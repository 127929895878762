import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';


const Location = () => (
    <Layout>
    <Helmet   
      htmlAttributes={{
      lang: 'en',
    }}>
      <title lang="en">Locate Us | On The Block Real Estate International</title>
      <meta name="description" content="On The Block Has Been Serving California For More Than 20+ Years, 
      Helping Buyers With Their First Homes & Income Properties. We Are Based Out Of Alamitos, CA." lang="en"/>
    </Helmet>
      <section className="pt-20 md:pt-40 JoinBack">
        <div className="mx-auto px-4">
            <h1 className="text-6xl font-bold leading-none text-white pb-40 drop-shadow-sm">
               Serving California<br/>
               And Beyond
            </h1>
        </div>
      </section>
  
      <section className="pt-5">
        <div className="container mx-auto">
        <h2 className="text-3xl my-5 mx-5 font-semibold">On The Block Real Estate International</h2>
        <div className="container mx-auto py-5 bg-gray-200 rounded-lg">
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
                <span className="font-bold">Address: </span>
                <a href="https://g.page/on-the-block-real-estate?share" target="_blank" rel="noopener nofollow noreferrer">
                10542 Calle Lee Suite 102, Los Alamitos, California 90720</a>
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
                <span className="font-bold">Office Phone: </span>
                <a href="tel:5629721390">(562)972-1390</a>
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
                <span className="font-bold">Don Sutton: </span>
                <a href="tel:5622555454">(562)225-5454</a>
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
                <span className="font-bold">Ernesto Marinez: </span>
                <a href="tel:3234078486">(323)407-8486</a>
            </p>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.1102056865157!2d-118.05921968479132!3d33.80946898067264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2e8c54a55555%3A0xe541c89966329c7d!2sOn%20The%20Block%20Real%20Estate!5e0!3m2!1ses-419!2sus!4v1621302286801!5m2!1ses-419!2sus" max-width="600" max-height="450" allowfullscreen="" loading="lazy" className="mt-8 mb-5 mx-5 text-xl font-light"></iframe>
        </div>
        </div>
      </section>
    </Layout>
  );

export default Location;